<template>
  <div>
    <h3 class="txt-center">
      Need help finding a topic you'd like to explore? Search below.
    </h3>

    <v-layout class="mt-3" justify-center>
      <v-flex
        xs6
        sm5
        md4
        lg4
        xl4
      >
        <v-autocomplete
          v-model="grades"
          :items="gradeList"
          flat
          outline
          color="black"
          label="Grade"
          item-text="name"
          item-value="name"
          return-object
          class="mx-auto pa-1 mb-1"
        >
          <template slot="selection" slot-scope="data">
            <template>
              <v-layout align-center justify-center>
                <v-img :src="data.item.avatar" max-height="32px" contain />
                {{ data.item.name }}
              </v-layout>
            </template>
          </template>
        </v-autocomplete>
      </v-flex>
      <v-flex
        xs6
        sm5
        md4
        lg4
        xl4
      >
        <v-autocomplete
          v-model="subs"
          :items="items"
          flat
          outline
          color="black"
          label="Subject"
          item-text="name"
          item-value="id"
          return-object
          class="mx-auto pa-1 mb-1"
        >
          <template slot="selection" slot-scope="data">
            <template>
              <v-layout align-center justify-center>
                <v-img :src="data.item.avatar" max-height="32px" contain />
                {{ data.item.name }}
              </v-layout>
            </template>
          </template>
        </v-autocomplete>
      </v-flex>
      <v-flex
        xs1
        sm1
        md1
        lg1
        xl1
      >
        <v-btn fab
               dark
               large
               align-center
               class="my-auto pa-1"
               @click="onSubmit()"
        >
          <v-img
            src="/assets/find.svg"
            aspect-ratio="1"
            max-width="25px"
            contain
          />
        </v-btn>
      </v-flex>
    </v-layout>
  <!--  <v-layout justify-center align-center>
    <v-btn fab dark large @click="onSubmit()">
      <v-img
        src="/assets/find.svg"
        aspect-ratio="1"
        max-width="25px"
        contain
      />
    </v-btn>
  </v-layout> -->
  </div>
<!--  <div id="finder-form" class="finder-form">
    <p class="finder-form_subtitle">
      Need help finding a topic you'd like to explore? Search here.
    </p>
    <form
      class="form"
      style="text-align:center"
      @submit.prevent="onSubmit"
    >
      <input v-model="finder.subject"
             required
             name="subject"
             placeholder="Keyword"
             type="text"
             autocomplete="off"
             class="namebox"
      >
      <button class="button" @click="onSubmit()">
        <v-icon> fa-search </v-icon>
      </button>
    </form>
    <br>
    <div v-if="isSending" align-center justify-center>
      <p class="finder-form_subtitle">
        Your search generated {{ results.topics.length }} results.
      </p>
      <v-layout
        v-for="item in results.topics"
        :key="item.key"
        align-start
        justify-center
      >
        <v-row
          row
          wrap
        >
          <v-hover v-slot:default="{ hover }">
            <v-card
              class="rounded-card"
              :elevation="hover ? 9:0"
              max-width="400px"
              align="center"
              justify="center"
              contain
            >
              <v-img class="imgC"
                     :aspect-ratio="1"
                     :src="item.image"
                     max-height="150px"
                     max-width="150px"
                     contain
              />
              <span class="subjects">
                {{ item.id }}
              </span>
            </v-card>
          </v-hover>
          <br>
        </v-row>
      </v-layout>
    </div>
  </div> -->
</template>
<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
export default {
  name: 'Finder',
  components: { },
  data () {
    return {
      finder: { },
      subs:[],
      grades:[],
      catArr:[],
      gradesList:[],
      link1:null,
      link2:null,
      id:null,
      items: [
        { id:1, name: 'Physics',     avatar: '/assets/masswhite.svg' },
        { id:2, name: 'Chemistry',   avatar: '/assets/proton.svg' },
        { id:3, name: 'Mathematics', avatar: '/assets/algebraII.svg' },
        //{ id:4, name: 'Programming', avatar: '/assets/brain.svg' },
      ],
      gradeList: [
        { name: '6th',  avatar: '/assets/domino-6gray.svg' },
        { name: '7th',  avatar: '/assets/domino-7gray.svg' },
        { name: '8th',  avatar: '/assets/domino-8gray.svg' },
        { name: '9th',  avatar: '/assets/domino-9gray.svg' },
        { name: '10th', avatar: '/assets/domino-10gray.svg' },
        { name: '11th', avatar: '/assets/domino-11gray.svg' },
        { name: '12th', avatar: '/assets/domino-12gray.svg' },
      ]
      //drawers: ['Permanent', 'Persistent', 'Temporary'],
    }
  },
//async
created () {
			// Store mutations
//      await this.$store.dispatch('navigation/getData');
      this.$store.commit('navigation/resetState');
			let title = 'Topic Finder';
			this.$store.commit('navigation/changeTitle', title);
			//this.$store.commit('navigation/changeMenu', title);
			let newshowhome = false;
			this.$store.commit('navigation/toggleshowhome', newshowhome);
			let newFind =true;
			this.$store.commit('navigation/replaceFind', newFind);
		},
    metaInfo() {
      return {
          title: 'Topic Finder',
          //  titleTemplate: '%s - Learn interactively',
            meta: [
              {vmid:'description', name: 'description', content: 'Find visually interactive lesson on science, engineering and mathematics'},
              {vmid:'keywords', name: 'keywords', content: "Search topics, edliy topics"},
              { vmid:'chars', charset: 'utf-8' },
              { vmid:'viewport', name: 'viewport', content: 'width=device-width, initial-scale=1' }]
            }
     },
   methods: {
   async onSubmit()
    {
      try{
      if(this.subs.name != null)
      {
        const getCat = await axios.get(process.env.VUE_APP_API+'categories');
      //  console.log("cats::::", getCat.data.data.categories);
        if(getCat.data.success)
        {
          this.catArr = getCat.data.data.categories.slice(3, 6);
          console.log("cats::::", this.catArr);
          for(let i in this.catArr)
          {
            console.log("cats::::", this.catArr[i].title);
            if(this.catArr[i].title == this.subs.name)
            {
              this.link1 = this.catArr[i].link+'?id='+this.catArr[i].id;
              this.id = this.catArr[i].id;
            }
          }
        }
      if(this.grades.name != null){
        console.log("Grade level specified::", this.grades.name);
        const getGrade = await axios.get(process.env.VUE_APP_API+'category/'+this.id+'/courses');
        if(getGrade.data.success)
        {
          this.gradesList = getGrade.data.data.category.courses;
          console.log("grade name ::", this.gradesList);
          for (let j in this.gradesList){
          if((this.gradesList[j].title).split(" ")[0] == this.grades.name)
          {
            this.link2 = this.grades.name+'?grade='+this.gradesList[j].id;
          }
        }
      }
      }
    }}
    catch (error) {
      console.error(error);
      this.$notify({
                group: 'notify',
                type: 'error',
                title: 'Subject not specified.',
                text: 'Specify a subject to start searching.'
      });
    }
      if(this.grades.name == null && this.subs.name == null) {
        //this.$router.push({path:"/"});
        this.$notify({
              group: 'notify',
              type: 'error',
              title: 'Subject and Grade not specified.',
              text: 'You need to specify a subject and a grade to search.'
            });
          }
      else if(this.grades.name != null && this.subs.name != null){
        this.$router.push({path:'/'+this.subs.name+this.link2});
      }
      else if(this.grades.name == null && this.subs.name != null)
      {
      this.$router.push({path:this.link1});
      }
      else if(this.grades.name!= null && this.subs.name == null){
        this.$notify({
                  group: 'notify',
                  type: 'error',
                  title: 'Subject NOT specified',
                  text: 'You need to specify a subject to search.'
        });
      }
    }
  }
}
</script>
<!--
<style lang="scss">
@import '@/styles/screen-sizes.scss';
.body {
	background: #f1f1f1;
	font-family: 'Roboto', sans-serif;
}
.finder-form .rounded-card {
  margin-top:auto;
  margin-bottom:auto;
	align:center;
  @include respond-to(less-smallest-screens) { height: 70px; width:70px; border-radius: 4.5px; }
  @include respond-to(smallest-screens) { height: 110px; width:110px; border-radius: 4.5px; }
  @include respond-to(small-screens) { height: 120px; width:120px; border-radius: 5.5px;}
  @include respond-to(medium-screens) { height:170px; width:170px; border-radius: 6.5px;}
  @include respond-to(large-screens) { height: 200px; width:200px; border-radius: 7.5px;}
  @include respond-to(largest-screens) { height: 220px; width:220px; border-radius: 8.5px;}
}
.finder-form .subjects{
text-decoration: none;
font-family: Oswald;
display: inline-block;
text-align:center;
position:relative;
@include respond-to(less-smallest-screens) {font-size:12px;}
@include respond-to(smallest-screens) {font-size:14px;}
@include respond-to(small-screens) {font-size:16px;top:10px;}
@include respond-to(medium-screens) {font-size:18px;top:10px;}
@include respond-to(large-screens) {font-size:20px; top:10px;}
@include respond-to(largest-screens) {font-size:25px; top:10px;}
}
.finder-form .thumbnail {
  display: inline-block;
  max-width: 100%; /* only this one important */
  margin-bottom: 1rem;
  border: solid 4px #fefefe;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  line-height: 0; }
.finder-form .imgC{
  justify:center;
  position:relative;
  @include respond-to(less-smallest-screens) {height:40px; top:5px;}
  @include respond-to(smallest-screens) {height: 40px;top:5.5px;}
  @include respond-to(small-screens) {height: 60px;top:6.5px;}
  @include respond-to(medium-screens) {height:100px;top:7.5px;}
  @include respond-to(large-screens) {height: 130px;top:8.5px;}
  @include respond-to(largest-screens) {height: 120px; top:10px;}
  padding-top: 10px;
  padding-bottom:20px;
  }
.finder-form .alert{
font-size:18px;
text-align:center;
text-justify:center;
}
.finder-form {
	font-size: 16px;
	font-family: Lato;
	max-width: 500px;
	width: 100%;
	border-radius:5px;
	margin: 0 auto;
}
.finder-form .separator {
	border-bottom: solid 1px #ccc;
	margin-bottom: 15px;
}
.finder-form .form {
	display: flex;
	flex-direction: column;
	font-size: 16px;
	max-width: 425px;
	border-radius:5px;
	text-justify:center;
	margin: 0 auto;
	padding-bottom:25px;
}

.finder-form .namebox {
	font-size: 16px;
	max-width: 450px;
	padding-right:10px;
	border: solid 1px #e8e8e8;
}

.finder-form_title {
	color: #333;
	font-size: 32px;
	text-align:center;
	padding-top:20px;
}

.finder-form_subtitle {
	color: #333;
	font-size: 18px;
	text-justify:center;
	padding-left:35px;
	padding-right:15px;
}

.finder-form input[type="email"],
.finder-form input[type="text"],
.finder-form textarea {
	border: solid 1px #e8e8e8;
	font-family: 'Roboto', sans-serif;
	padding: 10px 7px;
	margin-bottom: 15px;
	outline: none;}

.finder-form textarea {
	resize: none;
}

.finder-form input[type="email"],
.finder-form input[type="text"],
.finder-form textarea,
.finder-form .button {
	border: solid 1px #333;
	font-size: 16px;
	border-radius: 5px;
}

.finder-form .button {
	background: #da552f;
	border: solid 2px #da552f;
	color: white;
	cursor: pointer;
	padding: 10px 50px;
	text-align: center;
	text-transform: uppercase;
	font-size: 16px;
	border-radius: 5px;
	text-decoration: none;
}

.finder-form .button:hover {
	background: #ea532a;
	border: solid 2px #ea532a;
}

</style>
-->
